<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Create faq Category</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:600px;">
            <el-form ref="faqTitle" :model="FaqTitle" :rules="FaqTitleRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <el-form-item prop="title_ua">
                            <el-input placeholder="*Назва" v-model="FaqTitle.title_ua"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-en" v-show="langName=='en'">
                    <div class="item mt-20">
                        <el-form-item prop="title_en">
                            <el-input placeholder="*Назва" v-model="FaqTitle.title_en"></el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/category-faq')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="SaveFaqTitle"> Создать</button>
                </div>
            </el-form>
        </div>

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            loading: false,
            value1: '',
            FaqTitle: {
                title_ua: '',
                title_en: ''
            },
            FaqTitleRules: {
                title_ua: [{
                        required: true,
                        message: 'Please enter title',
                        trigger: 'change'
                    },
                    {
                        min: 5,
                        message: 'Length should be  5',
                        trigger: 'change'
                    }
                ],
                title_en: [{
                        required: true,
                        message: 'Please enter title',
                        trigger: 'change'
                    },
                    {
                        min: 5,
                        message: 'Length should  5',
                        trigger: 'change'
                    }
                ],
            }
        }

    },
    methods: {
        SaveFaqTitle() {
            this.$refs.faqTitle.validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.axios.post('/admin/faq-category', this.FaqTitle)
                        .then(res => {
                            console.log(res)
                            this.$router.push('/admin/category-faq')
                            this.$notify({
                                title: 'Save',
                                message: 'Ви успішно ввійшли',
                                type: 'success'
                            });
                        })
                        .catch(error => {
                            let ERRORS = error.response.data.message;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            this.$notify.error({
                                title: 'Произошла ошибка',
                                message: error_msg ? error_msg : "Произошла ошибка",
                            });

                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    if (this.FaqTitle.title_ua) {
                        this.langName = "en"
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}
</style>
